import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { graphql } from "gatsby";
import Footer from "../components/Footer/Footer";
import Topbar from "../components/Topbar/Topbar";
import config from "../../data/SiteConfig";
import Layout from "../components/layout";

const Wrapper = styled.div`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.75;
  margin: auto; // IE
  margin: calc(var(--gutter) * 2) auto calc(var(--gutter) * 4);
  max-width: 1000px; // IE
  max-width: var(--max-width);
  padding: var(--gutter);
`;

export default class CookiePolicy extends React.Component {
  render() {
    const categories = this.props.data.categories.edges;
    return (
      <Layout>
        <div className="index-container">
          <Helmet title="Cookie policy">
            <meta name="theme-color" content={config.themes.default.main} />
          </Helmet>
          <Topbar
            categories={categories}
            sharePath="/cookie-policy/"
            shareTitle="Cookie Policy"
          />
          <Wrapper>
            <h1>Cookie Policy</h1>
            <p>
              I Cookie sono costituiti da porzioni di codice installate
              all'interno del browser che assistono il Titolare nell’erogazione
              del servizio in base alle finalità descritte. Alcune delle
              finalità di installazione dei Cookie potrebbero, inoltre,
              necessitare del consenso dell'Utente.
            </p>
            <h3>Cookie tecnici e di statistica aggregata</h3>
            <ul>
              <li>
                <h3>Attività strettamente necessarie al funzionamento</h3>
                <p>
                  Questa Applicazione utilizza Cookie per salvare la sessione
                  dell&rsquo;Utente e per svolgere altre attività strettamente
                  necessarie al funzionamento dello stesso, ad esempio in
                  relazione alla distribuzione del traffico.
                </p>
              </li>
              <li>
                <h3>
                  Attività di salvataggio delle preferenze, ottimizzazione e
                  statistica
                </h3>
                <p>
                  Questa Applicazione utilizza Cookie per salvare le preferenze
                  di navigazione ed ottimizzare l&rsquo;esperienza di
                  navigazione dell&rsquo;Utente. Fra questi Cookie rientrano, ad
                  esempio, quelli per impostare la lingua e la valuta o per la
                  gestione di statistiche da parte del Titolare del sito.
                </p>
              </li>
            </ul>
            <h3>
              Altre tipologie di Cookie o strumenti terzi che potrebbero farne
              utilizzo
            </h3>
            <p>
              Alcuni dei servizi elencati di seguito raccolgono statistiche in
              forma aggregata e potrebbero non richiedere il consenso
              dell&rsquo;Utente o potrebbero essere gestiti direttamente dal
              Titolare – a seconda di quanto descritto – senza l&rsquo;ausilio
              di terzi.
            </p>
            <p>
              Qualora fra gli strumenti indicati in seguito fossero presenti
              servizi gestiti da terzi, questi potrebbero – in aggiunta a quanto
              specificato ed anche all’insaputa del Titolare – compiere attività
              di tracciamento dell’Utente. Per informazioni dettagliate in
              merito, si consiglia di consultare le privacy policy dei servizi
              elencati.
            </p>
            <ul>
              <li>
                <h3>Statistica</h3>
                <p>
                  I servizi contenuti nella presente sezione permettono al
                  Titolare del Trattamento di monitorare e analizzare i dati di
                  traffico e servono a tener traccia del comportamento
                  dell’Utente.
                </p>
                <h4>Google Analytics (Google Inc.)</h4>
                <p>
                  Google Analytics &egrave; un servizio di analisi web fornito
                  da Google Inc. (&ldquo;Google&rdquo;). Google utilizza i Dati
                  Personali raccolti allo scopo di tracciare ed esaminare
                  l&rsquo;utilizzo di questa Applicazione, compilare report e
                  condividerli con gli altri servizi sviluppati da Google.
                  <br />
                  Google potrebbe utilizzare i Dati Personali per
                  contestualizzare e personalizzare gli annunci del proprio
                  network pubblicitario.
                </p>
                <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                <p>
                  Luogo del trattamento: USA &ndash;&nbsp;
                  <a
                    href="https://www.google.com/intl/it/policies/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=it"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Opt Out
                  </a>
                </p>
              </li>
            </ul>
            <h3>Come posso controllare l&rsquo;installazione di Cookie?</h3>
            <p>
              In aggiunta a quanto indicato in questo documento, l&rsquo;Utente
              può gestire le preferenze relative ai Cookie direttamente
              all&rsquo;interno del proprio browser ed impedire – ad esempio –
              che terze parti possano installarne. Tramite le preferenze del
              browser è inoltre possibile eliminare i Cookie installati in
              passato, incluso il Cookie in cui venga eventualmente salvato il
              consenso all&rsquo;installazione di Cookie da parte di questo
              sito. È importante notare che disabilitando tutti i Cookie, il
              funzionamento di questo sito potrebbe essere compromesso.
              L&rsquo;Utente può trovare informazioni su come gestire i Cookie
              nel suo browser ai seguenti indirizzi:&nbsp;
              <a
                href="https://support.google.com/chrome/answer/95647?hl=it&amp;p=cpn_cookies"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Chrome
              </a>
              ,
              <a
                href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mozilla Firefox
              </a>
              ,
              <a
                href="https://support.apple.com/kb/PH19214?viewlocale=it_IT&amp;locale=en_US"
                target="_blank"
                rel="noopener noreferrer"
              >
                Apple Safari
              </a>
               e
              <a
                href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies"
                target="_blank"
                rel="noopener noreferrer"
              >
                Microsoft Windows Explorer
              </a>
              .
            </p>
            <p>
              In caso di servizi erogati da terze parti, l&rsquo;Utente può
              inoltre esercitare il proprio diritto ad opporsi al tracciamento
              informandosi tramite la privacy policy della terza parte, tramite
              il link di opt out se esplicitamente fornito o contattando
              direttamente la stessa.
            </p>
            <p>
              Fermo restando quanto precede, il Titolare informa che l’Utente
              può avvalersi di&nbsp;
              <a
                href="https://www.youronlinechoices.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Your Online Choices
              </a>
              . Attraverso tale servizio è possibile gestire le preferenze di
              tracciamento della maggior parte degli strumenti pubblicitari. Il
              Titolare, pertanto, consiglia agli Utenti di utilizzare tale
              risorsa in aggiunta alle informazioni fornite dal presente
              documento.
            </p>
            <h2>Titolare del Trattamento dei Dati</h2>
            <p>Vivigratis SA - Via Livio, 12 - 6830 Chiasso (Svizzera)</p>
            <p>
              <strong>Indirizzo email del Titolare:</strong> info@vivigratis.com
            </p>
            <p>
              Dal momento che l&rsquo;installazione di Cookie e di altri sistemi
              di tracciamento operata da terze parti tramite i servizi
              utilizzati all&rsquo;interno di questa Applicazione non può essere
              tecnicamente controllata dal Titolare, ogni riferimento specifico
              a Cookie e sistemi di tracciamento installati da terze parti è da
              considerarsi indicativo. Per ottenere informazioni complete,
              consulta la privacy policy degli eventuali servizi terzi elencati
              in questo documento.
            </p>
            <p>
              Vista l&rsquo;oggettiva complessità legata
              all&rsquo;identificazione delle tecnologie basate sui Cookie ed
              alla loro integrazione molto stretta con il funzionamento del web,
              l&rsquo;Utente è invitato a contattare il Titolare qualora volesse
              ricevere qualunque approfondimento relativo all&rsquo;utilizzo dei
              Cookie stessi e ad eventuali utilizzi degli stessi – ad esempio ad
              opera di terzi – effettuati tramite questo sito.
            </p>
            <h3>Definizioni e riferimenti legali</h3>
            <h4>Dati Personali (o Dati)</h4>
            <p>
              Costituisce dato personale qualunque informazione relativa a
              persona fisica, identificata o identificabile, anche
              indirettamente, mediante riferimento a qualsiasi altra
              informazione, ivi compreso un numero di identificazione personale.
            </p>
            <h4>Dati di Utilizzo</h4>
            <p>
              Sono le informazioni raccolte in maniera automatica da questa
              Applicazione (o dalle applicazioni di parti terze che questa
              Applicazione utilizza), tra le quali: gli indirizzi IP o i nomi a
              dominio dei computer utilizzati dall’Utente che si connette con
              questa Applicazione, gli indirizzi in notazione URI (Uniform
              Resource Identifier), l’orario della richiesta, il metodo
              utilizzato nel sottoporre la richiesta al server, la dimensione
              del file ottenuto in risposta, il codice numerico indicante lo
              stato della risposta dal server (buon fine, errore, ecc.) il paese
              di provenienza, le caratteristiche del browser e del sistema
              operativo utilizzati dal visitatore, le varie connotazioni
              temporali della visita (ad esempio il tempo di permanenza su
              ciascuna pagina) e i dettagli relativi all’itinerario seguito
              all’interno dell’Applicazione, con particolare riferimento alla
              sequenza delle pagine consultate, ai parametri relativi al sistema
              operativo e all’ambiente informatico dell’Utente.
            </p>
            <h4>Utente</h4>
            <p>
              L&rsquo;individuo che utilizza questa Applicazione, che deve
              coincidere con l&rsquo;Interessato o essere da questo autorizzato
              ed i cui Dati Personali sono oggetto del trattamento.
            </p>
            <h4>Interessato</h4>
            <p>
              La persona fisica o giuridica cui si riferiscono i Dati Personali.
            </p>
            <h4>Responsabile del Trattamento (o Responsabile)</h4>
            <p>
              La persona fisica, giuridica, la pubblica amministrazione e
              qualsiasi altro ente, associazione od organismo preposti dal
              Titolare al trattamento dei Dati Personali, secondo quanto
              predisposto dalla presente privacy policy.
            </p>
            <h4>Titolare del Trattamento (o Titolare)</h4>
            <p>
              La persona fisica, giuridica, la pubblica amministrazione e
              qualsiasi altro ente, associazione od organismo cui competono,
              anche unitamente ad altro titolare, le decisioni in ordine alle
              finalità, alle modalità del trattamento di dati personali ed agli
              strumenti utilizzati, ivi compreso il profilo della sicurezza, in
              relazione al funzionamento e alla fruizione di questa
              Applicazione. Il Titolare del Trattamento, salvo quanto
              diversamente specificato, è il proprietario di questa
              Applicazione.
            </p>
            <h4>Questa Applicazione</h4>
            <p>
              Lo strumento hardware o software mediante il quale sono raccolti i
              Dati Personali degli Utenti.
            </p>
            <h4>Cookie</h4>
            <p>
              Piccola porzione di dati conservata all&rsquo;interno del
              dispositivo dell&rsquo;Utente.
            </p>
            <hr />
            <h4>Riferimenti legali</h4>
            <p>
              Avviso agli Utenti europei: la presente informativa privacy è
              redatta in adempimento degli obblighi previsti dall’Art. 10 della
              Direttiva n. 95/46/CE, nonché a quanto previsto dalla Direttiva
              2002/58/CE, come aggiornata dalla Direttiva 2009/136/CE, in
              materia di Cookie.
            </p>
            <p>
              Questa informativa privacy riguarda esclusivamente questa
              Applicazione.
            </p>
            <p>Ultima modifica: 12 Aprile 2018</p>
          </Wrapper>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CookiePolicyQuery {
    categories: allCrossCastCategory(
      filter: { parentCategory: { slug: { eq: null } } }
    ) {
      edges {
        node {
          slug
          title
          position
          subcategories {
            slug
            title
          }
        }
      }
    }
  }
`;
